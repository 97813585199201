<template>
	<div id="app" v-if="isLogado">
		<div class="wrapper">
			<div class="body-loading" v-if="isCarregando == true">
				<span class="loader"></span>
			</div>

			<!-- Menu NavBar -->
			<NavBar />
			
			<!-- Corpo sistema -->
			<div class="page-wrapper">
				<div class="page-content">
					<ul class="nav nav-header">
						<li class="nav-item" v-for="(tab, index) in tabs" :key="index">
							<router-link class="nav-link" :to='tab.path +"?"+ JSON.stringify(tab.query).replace(/[\{\}]|["]/g, "").replace(/[:]/g, "=").replace(/[,]/g, "&")'
								:class="[tab.path == $route.path ? 'active' : '', index == 0 ? 'pe-3' : '']">
								<span>
									<i :class="tab.icon"></i>{{ tab.nome.replace(/_/g, " ").replace(/-/g, "/") }}
									<span v-if="tab.nome == 'Nota'"><small>|</small> {{ tab.query.chaveNota }}</span>
									<span v-else-if="tab.nome == 'Entrada_de_mercadorias'"><small>|</small> {{ tab.query.chaveNota }}</span>
									<span v-else-if="tab.nome == 'Caixa'"><small>|</small> Loja {{ tab.query.numeroLoja }} - PDV {{ tab.query.numeroPdv }}</span>
									<span v-else-if="tab.nome == 'Notícia'"><small>|</small> {{ tab.query.titulo }}</span>
									<span v-else-if="tab.nome == 'Orçamento'"><small>|</small> {{ tab.query.codigo }}</span>
								</span>
							</router-link>
							<i class="fal fa-times" @click="deleteTabData(index)" v-if="index != 0"></i>
						</li>
					</ul>

					<router-view />
				</div>

				<a href="javaScript:;" class="back-to-top"><i class='bx bxs-up-arrow-alt'></i></a>
			</div>

			<div class="overlay toggle-icon"></div>
			
			<footer class="page-footer">
				<span class="ps-2 float-start d-none d-md-block">Copyright © {{ new Date().getFullYear() }}. Todos direitos reservados</span>
				<span class="pe-2 float-end">OneWay Systems</span>
			</footer>

			<!-- Mudar tema -->
         <div class="switcher-wrapper">
            <div class="switcher-btn"><i class='bx bx-cog bx-spin'></i></div>
            <div class="switcher-body">
               <div class="d-flex align-items-center">
                  <h5 class="mb-0 text-uppercase">Tema</h5>
                  <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
               </div>
               <hr/>
               <h6 class="mb-0"><i class="far fa-palette color-theme font-13 me-1"></i> Estilo</h6>
               <hr/>
               <div class="row row-cols-2 m-0 align-items-center">
                  <div class="form-check pe-1">
                     <input class="form-check-input" type="radio" name="flexRadioDefault" id="lightmode" :checked="theme == 'light-theme'">
                     <label class="form-check-label ps-1" for="lightmode">Claro</label>
                  </div>
                  <div class="form-check pe-1">
                     <input class="form-check-input" type="radio" name="flexRadioDefault" id="darkmode" :checked="theme == 'dark-theme'">
                     <label class="form-check-label ps-1" for="darkmode">Escuro</label>
                  </div>
               </div>
               <div class="row row-cols-2 m-0 align-items-center">
                  <div class="form-check pe-1">
                     <input class="form-check-input" type="radio" name="flexRadioDefault" id="semidark" :checked="theme == 'semi-dark'">
                     <label class="form-check-label ps-1" for="semidark">Semi Escuro</label>
                  </div>
						<div class="form-check pe-1">
							<input class="form-check-input" type="radio" id="minimaltheme" name="flexRadioDefault" :checked="theme == 'minimal-theme'">
							<label class="form-check-label ps-1" for="minimaltheme">Minimal</label>
						</div>
               </div>
               <hr/>
               <h6 class="mb-0"><i class="far fa-paint-brush color-theme font-13 me-1"></i> Cor</h6>
               <hr/>
               <div class="header-colors-indigators">
                  <div class="row row-cols-auto g-3">
                     <div class="col">
                        <div class="indigator headercolor1" id="headercolor1"></div>
                     </div>
                     <!-- <div class="col">
                        <div class="indigator headercolor2" id="headercolor2"></div>
                     </div> -->
                     <div class="col">
                        <div class="indigator headercolor3" id="headercolor3"></div>
                     </div>
                     <div class="col">
                        <div class="indigator headercolor4" id="headercolor4"></div>
                     </div>
                     <div class="col">
                        <div class="indigator headercolor5" id="headercolor5"></div>
                     </div>
                     <div class="col">
                        <div class="indigator headercolor6" id="headercolor6"></div>
                     </div>
                     <div class="col">
                        <div class="indigator headercolor7" id="headercolor7"></div>
                     </div>
                     <div class="col">
                        <div class="indigator headercolor8" id="headercolor8"></div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
		</div>
	</div>

	<div id="app" class="logout bg-login" v-else>
		<div class="body-loading" v-if="isCarregando == true">
			<span class="loader"></span>
		</div>

		<Login />
	</div>
</template>

<script>

import Vue from 'vue'
import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import Login from '@/views/Login.vue'
import NavBar from '@/components/MenuNavBar.vue'

export default {
	name: 'App',
	data: function() {
		return {
         themeColor: (localStorage.carolinaERP_color == null ? 'color-header headercolor1' : 'color-header '+ localStorage.carolinaERP_color),
         theme: (localStorage.carolinaERP_theme == null ? 'light-theme' : localStorage.carolinaERP_theme)
		}
	},
	computed: {
		... mapState({
			isCarregando: state => state.isCarregando,
			urlAjuda: state => state.urlAjuda,
			isLogado: state => state.isLogado,
			tabs: state => state.tabs
		})
	},
	watch: {
		isLogado : function () {
			this.configJs()
		},
		// tabs : {
		// 	handler: function(newVal, oldVal) {
		// 		oldVal.forEach(tab => {
		// 			if (tab.data != null)
		// 				console.log("old "+ tab.id +": "+ tab.path +" | "+ tab.data.pesquisa.retorno.resultado.length)
		// 		});
		// 		console.log("-----------------------------")
		// 		newVal.forEach(tab => {
		// 			if (tab.data != null)
		// 				console.log("new "+ tab.id +": "+ tab.path +" | "+ tab.data.pesquisa.retorno.resultado.length)
		// 		});
		// 		console.log("============================")
		// 	},
		// 	deep: true
		// }
	},
	components: {
		NavBar, Login
	},
	methods: {
		deleteTabData : function (index) {
			this.$store.dispatch('deleteTabData', index)
		},
		configJs : function () {
			Vue.nextTick(function() {
				$(window).on("scroll", function () {
					if ($(this).scrollTop() > 300) {
						$('.back-to-top').fadeIn();
					} else {
						$('.back-to-top').fadeOut();
					}
				});
		
				$('.back-to-top').on("click", function () {
					$("html, body").animate({
						scrollTop: 0
					}, 600);
					return false;
				});
		
				$(".switcher-btn").on("click", function () {
					$(".switcher-wrapper").toggleClass("switcher-toggled");
				});
				$(".close-switcher").on("click", function () {
					$(".switcher-wrapper").removeClass("switcher-toggled");
				});
				$("#lightmode").on("click", function () {
					localStorage.carolinaERP_theme = 'light-theme';
					$('html').addClass('light-theme');
					$('html').removeClass('dark-theme semi-dark minimal-theme');
				});
				$("#darkmode").on("click", function () {
					localStorage.carolinaERP_theme = 'dark-theme';
					$('html').addClass('dark-theme');
					$('html').removeClass('light-theme semi-dark minimal-theme');
				});
				$("#semidark").on("click", function () {
					localStorage.carolinaERP_theme = 'semi-dark';
					$('html').addClass('semi-dark');
					$('html').removeClass('light-theme dark-theme minimal-theme');
				});
				$("#minimaltheme").on("click", function () {
					localStorage.carolinaERP_theme = 'minimal-theme';
					$('html').addClass('minimal-theme');
					$('html').removeClass('light-theme dark-theme semi-dark');
				});
				$("#headercolor1").on("click", function () {
					localStorage.carolinaERP_color = 'headercolor1';
					$("html").addClass("color-header headercolor1");
					$("html").removeClass("headercolor2 headercolor3 headercolor4 headercolor5 headercolor6 headercolor7 headercolor8");
				});
				$("#headercolor2").on("click", function () {
					localStorage.carolinaERP_color = 'headercolor2';
					$("html").addClass("color-header headercolor2");
					$("html").removeClass("headercolor1 headercolor3 headercolor4 headercolor5 headercolor6 headercolor7 headercolor8");
				});
				$("#headercolor3").on("click", function () {
					localStorage.carolinaERP_color = 'headercolor3';
					$("html").addClass("color-header headercolor3");
					$("html").removeClass("headercolor1 headercolor2 headercolor4 headercolor5 headercolor6 headercolor7 headercolor8");
				});
				$("#headercolor4").on("click", function () {
					localStorage.carolinaERP_color = 'headercolor4';
					$("html").addClass("color-header headercolor4");
					$("html").removeClass("headercolor1 headercolor2 headercolor3 headercolor5 headercolor6 headercolor7 headercolor8");
				});
				$("#headercolor5").on("click", function () {
					localStorage.carolinaERP_color = 'headercolor5';
					$("html").addClass("color-header headercolor5");
					$("html").removeClass("headercolor1 headercolor2 headercolor4 headercolor3 headercolor6 headercolor7 headercolor8");
				});
				$("#headercolor6").on("click", function () {
					localStorage.carolinaERP_color = 'headercolor6';
					$("html").addClass("color-header headercolor6");
					$("html").removeClass("headercolor1 headercolor2 headercolor4 headercolor5 headercolor3 headercolor7 headercolor8");
				});
				$("#headercolor7").on("click", function () {
					localStorage.carolinaERP_color = 'headercolor7';
					$("html").addClass("color-header headercolor7");
					$("html").removeClass("headercolor1 headercolor2 headercolor4 headercolor5 headercolor6 headercolor3 headercolor8");
				});
				$("#headercolor8").on("click", function () {
					localStorage.carolinaERP_color = 'headercolor8';
					$("html").addClass("color-header headercolor8");
					$("html").removeClass("headercolor1 headercolor2 headercolor4 headercolor5 headercolor6 headercolor7 headercolor3");
				});
			}.bind(this));
		}
	},
	mounted() {
		this.$axios.interceptors.response.use((response) => {
			return response;

		}, (error) => {
			if (!error.response) {
				Swal.fire({
					icon: 'error',
					title: 'Erro ao executar requisição',
					text: (error.message == 'Network Error' ? 'O sistema pode estar em manutenção, para mais informações contate o suporte.' : error.message),
					showCancelButton: true,
					confirmButtonText: `Central de ajuda`,
					cancelButtonText: `Fechar`
					
				}).then((result) => {
					if (result.isConfirmed) {
						window.open(this.urlAjuda)
					}
				})
			}

			return Promise.reject(error);
		});

		$("html").attr("class", this.theme +' '+ this.themeColor)
		this.$store.dispatch('verificarCredenciais')
	}
}

</script>
<template>
   <a class="dropdown-item p-2" href="javascript:;" @click="irParaTela">
      <div class="row m-0 align-items-center">
         <div class="w-max-content ps-1 pe-12" v-if="tipo == 'Notícia'">
            <img :src="notificacao.icone == null ? '' : notificacao.icone" class="rounded" @error="imageError" />
         </div>
         <div class="w-max-content px-1" v-else>
            <div class="notify color-theme" v-if="route && route.meta"><i :class="route.meta.icon"></i></div>
            <div class="notify color-theme" v-else><i class="fal fa-bullhorn"></i></div>
         </div>
         <div class="col px-1">
            <h6 class="msg-name text-wrap" :class="tipo == 'Notícia' ? 'limitador-2 mb-1' : 'limitador-1'">{{ notificacao.titulo }}</h6>
            <p class="msg-time text-wrap limitador-1 mb-1" v-if="tipo == 'Notificação'">{{ notificacao.descricao }}</p>
            <p class="msg-time text-wrap limitador-1 text-end font-10">
               <span>{{ String(notificacao.data.dayOfMonth).padStart(2, '0') +'/'+ String(notificacao.data.monthValue).padStart(2, '0') +'/'+ notificacao.data.year }} </span>
               <span>{{ String(notificacao.data.hour).padStart(2, '0') +':'+ String(notificacao.data.minute).padStart(2, '0') }}</span>
            </p>
         </div>
      </div>
   </a>
</template>

<script>

import $ from 'jquery'
import router from '@/router'
import { mapState } from 'vuex'

export default {
   name: 'Notificacao',
   props: ["notificacao", "index", "tipo"],
   data: function () {
		return {
         route: router.options.routes.find(tela => tela.name == this.notificacao.tela)
      }
   },
   computed: {
      ... mapState({
         tabs: state => state.tabs
      })
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      irParaTela : function () {
         $(".dropdown-toggle.show").dropdown("hide")

         if (this.tipo == 'Notícia') {
            let tabNoticia = this.tabs.find(tab => tab.nome == 'Notícia' && tab.query.id == this.notificacao.id)
            router.push('/noticia/'+ (tabNoticia ? tabNoticia.id : this.tabs.length) +'?id='+ this.notificacao.id +'&titulo='+ this.notificacao.titulo)

         } else if (this.tipo == 'Notificação') {
            router.push(this.route.path.replace(':idTab', this.tabs.length))
         }
      }
   }
}

</script>

<style scoped>

img {
   width: 75px;
   height: 50px;
   object-fit: cover;
   object-position: center;
   border-radius: 3px;
   border: 1px solid #ffffff1f;
}

</style>
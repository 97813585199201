import Vue from 'vue'
import $ from 'jquery'
import store from '@/store'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
   {
      path: '/paginaInicial/:idTab',
      name: 'Página_inicial',
      component: () => import('../views/PaginaInicial.vue'),
      meta: {'icon': 'bx bx-home-circle'}
   },
   {
      path: '/vasilhames/:idTab',
      name: 'Vasilhames',
      component: () => import('../views/pages/automacao/Vasilhames.vue'),
      meta: {'icon': 'bx bx-bot'}
   },
   {
      path: '/cargas/:idTab',
      name: 'Cargas',
      component: () => import('../views/pages/automacao/Cargas.vue'),
      meta: {'icon': 'bx bx-bot'}
   },
   {
      path: '/clubeDesconto/:idTab',
      name: 'Clube_de_desconto',
      component: () => import('../views/pages/automacao/ClubeDesconto.vue'),
      meta: {'icon': 'bx bx-bot'}
   },
   {
      path: '/combosPacks/:idTab',
      name: 'Combos_(Packs)',
      component: () => import('../views/pages/automacao/CombosPacks.vue'),
      meta: {'icon': 'bx bx-bot'}
   },
   {
      path: '/ofertas/:idTab',
      name: 'Ofertas',
      component: () => import('../views/pages/automacao/Ofertas.vue'),
      meta: {'icon': 'bx bx-bot'}
   },
   {
      path: '/receitas/:idTab',
      name: 'Receitas',
      component: () => import('../views/pages/automacao/Receitas.vue'),
      meta: {'icon': 'bx bx-bot'}
   },
   {
      path: '/clientes/:idTab',
      name: 'Clientes',
      component: () => import('../views/pages/automacao/Clientes.vue'),
      meta: {'icon': 'bx bx-bot'}
   },
   {
      path: '/cotacoes/:idTab',
      name: 'Cotações',
      component: () => import('../views/pages/compras/Cotacoes.vue'),
      meta: {'icon': 'bx bx-cart'}
   },
   {
      path: '/pedidosConsulta/:idTab',
      name: 'Consulta_pedidos',
      component: () => import('../views/pages/compras/PedidosConsulta.vue'),
      meta: {'icon': 'bx bx-cart'}
   },
   {
      path: '/pedidosCriacao/:idTab',
      name: 'Criação_pedidos',
      component: () => import('../views/pages/compras/PedidosCriacao.vue'),
      meta: {'icon': 'bx bx-cart'}
   },
   {
      path: '/gerenciamentoEstoque/:idTab',
      name: 'Gerenciamento_de_estoques',
      component: () => import('../views/pages/configuracoes/GerenciamentoEstoque.vue'),
      meta: {'icon': 'bx bx-box'}
   },
   {
      path: '/operacoesEstoque/:idTab',
      name: 'Operações_estoque',
      component: () => import('../views/pages/configuracoes/OperacoesEstoque.vue'),
      meta: {'icon': 'bx bx-box'}
   },
   {
      path: '/lojas/:idTab',
      name: 'Lojas',
      component: () => import('../views/pages/configuracoes/Lojas.vue'),
      meta: {'icon': 'bx bx-cog'}
   },
   {
      path: '/unidadesMedida/:idTab',
      name: 'Unidades_de_medida',
      component: () => import('../views/pages/configuracoes/UnidadesMedida.vue'),
      meta: {'icon': 'bx bx-cog'}
   },
   {
      path: '/usuarios/:idTab',
      name: 'Usuários',
      component: () => import('../views/pages/configuracoes/Usuarios.vue'),
      meta: {'icon': 'bx bx-cog'}
   },
   {
      path: '/minhaConta/:idTab',
      name: 'Minha_conta',
      component: () => import('../views/pages/configuracoes/MinhaConta.vue'),
      meta: {'icon': 'bx bx-cog'}
   },
   {
      path: '/relatorios/:idTab',
      name: 'Relatórios',
      component: () => import('../views/pages/convenios/Relatorios.vue'),
      meta: {'icon': 'bx bx-wallet'}
   },
   {
      path: '/afiliados/:idTab',
      name: 'Afiliados',
      component: () => import('../views/pages/convenios/Afiliados.vue'),
      meta: {'icon': 'bx bx-wallet'}
   },
   {
      path: '/configuracoes/:idTab',
      name: 'Configurações',
      component: () => import('../views/pages/ecommerce/Configuracoes.vue'),
      meta: {'icon': 'bx bx-at'}
   },
   {
      path: '/pedidosEcommerce/:idTab',
      name: 'Pedidos_ecommerce',
      component: () => import('../views/pages/ecommerce/Pedidos.vue'),
      meta: {'icon': 'bx bx-at'}
   },
   {
      path: '/produtos/:idTab',
      name: 'Produtos',
      component: () => import('../views/pages/ecommerce/Produtos.vue'),
      meta: {'icon': 'bx bx-at'}
   },
   {
      path: '/vendasEcommerce/:idTab',
      name: 'Vendas_ecommerce',
      component: () => import('../views/pages/ecommerce/Vendas.vue'),
      meta: {'icon': 'bx bx-at'}
   },
   {
      path: '/notasEntregues/:idTab',
      name: 'Notas_entregues',
      component: () => import('../views/pages/recebimento/NotasEntregues.vue'),
      meta: {'icon': 'bx bx-package'}
   },
   {
      path: '/entradaNota/:idTab',
      name: 'Entrada_de_notas',
      component: () => import('../views/pages/recebimento/EntradaNota.vue'),
      meta: {'icon': 'bx bx-package'}
   },
   {
      path: '/entradaMercadorias/:idTab',
      props: route => ({ query: route.query }),
      name: 'Entrada_de_mercadorias',
      component: () => import('../views/pages/recebimento/EntradaMercadorias.vue'),
      meta: {'icon': 'bx bx-package'}
   },
   {
      path: '/planoContas/:idTab',
      name: 'Plano_de_contas',
      component: () => import('../views/pages/financeiro/PlanoContas.vue'),
      meta: {'icon': 'bx bx-dollar'}
   },
   {
      path: '/lancamentoGastos/:idTab',
      name: 'Lançamento_de_gastos',
      component: () => import('../views/pages/financeiro/LancamentoGastos.vue'),
      meta: {'icon': 'bx bx-dollar'}
   },
   {
      path: '/nota/:idTab',
      props: route => ({ query: route.query }),
      name: 'Nota',
      component: () => import('../views/pages/fiscal/Nota.vue'),
      meta: {'icon': 'bx bx-file-blank'}
   },
   {
      path: '/tributacoes/:idTab',
      name: 'Tributações',
      component: () => import('../views/pages/fiscal/Tributacoes.vue'),
      meta: {'icon': 'bx bx-file-blank'}
   },
   {
      path: '/emitirNota/:idTab',
      name: 'Emitir_nota',
      component: () => import('../views/pages/fiscal/EmitirNota.vue'),
      meta: {'icon': 'bx bx-file-blank'}
   },
   {
      path: '/fornecedores/:idTab',
      name: 'Fornecedores',
      component: () => import('../views/pages/fiscal/Fornecedores.vue'),
      meta: {'icon': 'bx bx-file-blank'}
   },
   {
      path: '/notas/:idTab',
      name: 'Notas',
      component: () => import('../views/pages/fiscal/Notas.vue'),
      meta: {'icon': 'bx bx-file-blank'}
   },
   {
      path: '/categorias/:idTab',
      name: 'Categorias',
      component: () => import('../views/pages/produtos/Categorias.vue'),
      meta: {'icon': 'bx bx-coffee-togo'}
   },
   {
      path: '/marcas/:idTab',
      name: 'Marcas',
      component: () => import('../views/pages/produtos/Marcas.vue'),
      meta: {'icon': 'bx bx-coffee-togo'}
   },
   {
      path: '/estoquesProduto/:idTab',
      name: 'Estoque_por_produto',
      component: () => import('../views/pages/produtos/EstoquesProduto.vue'),
      meta: {'icon': 'bx bx-coffee-togo'}
   },
   {
      path: '/ajusteEstoque/:idTab',
      name: 'Ajuste_de_estoque',
      component: () => import('../views/pages/produtos/AjusteEstoque.vue'),
      meta: {'icon': 'bx bx-coffee-togo'}
   },
   {
      path: '/meusProdutos/:idTab',
      name: 'Meus_produtos',
      component: () => import('../views/pages/produtos/MeusProdutos.vue'),
      meta: {'icon': 'bx bx-coffee-togo'}
   },
   {
      path: '/precos/:idTab',
      name: 'Preços',
      component: () => import('../views/pages/produtos/Precos.vue'),
      meta: {'icon': 'bx bx-coffee-togo'}
   },
   {
      path: '/entradasSaidasRelatorios/:idTab',
      name: 'Relatórios_entradas_e_saidas',
      component: () => import('../views/pages/resultados/EntradasSaidasRelatorios.vue'),
      meta: {'icon': 'bx bx-line-chart'}
   },
   {
      path: '/entradasSaidasNotas/:idTab',
      name: 'Notas_entradas_e_saidas',
      component: () => import('../views/pages/resultados/EntradasSaidasNotas.vue'),
      meta: {'icon': 'bx bx-line-chart'}
   },
   {
      path: '/ticketMedio/:idTab',
      name: 'Ticket_médio',
      component: () => import('../views/pages/resultados/TicketMedio.vue'),
      meta: {'icon': 'bx bx-line-chart'}
   },
   {
      path: '/vendas/:idTab',
      name: 'Vendas',
      component: () => import('../views/pages/resultados/Vendas.vue'),
      meta: {'icon': 'bx bx-line-chart'}
   },
   {
      path: '/saidasProdutos/:idTab',
      name: 'Saídas_de_produtos',
      component: () => import('../views/pages/resultados/SaidasProdutos.vue'),
      meta: {'icon': 'bx bx-line-chart'}
   },
   {
      path: '/entradasProdutos/:idTab',
      name: 'Entradas_de_produtos',
      component: () => import('../views/pages/resultados/EntradasProdutos.vue'),
      meta: {'icon': 'bx bx-line-chart'}
   },
   {
      path: '/orcamentos/:idTab',
      name: 'Orçamentos',
      component: () => import('../views/pages/pdv/Orcamentos.vue'),
      meta: {'icon': 'bx bx-desktop'}
   },
   {
      path: '/orcamento/:idTab',
      props: route => ({ query: route.query }),
      name: 'Orçamento',
      component: () => import('../views/pages/pdv/Orcamento.vue'),
      meta: {'icon': 'bx bx-desktop'}
   },
   {
      path: '/caixas/:idTab',
      name: 'Caixas',
      component: () => import('../views/pages/pdv/Caixas.vue'),
      meta: {'icon': 'bx bx-desktop'}
   },
   {
      path: '/fechamentos/:idTab',
      name: 'Fechamentos',
      component: () => import('../views/pages/pdv/Fechamentos.vue'),
      meta: {'icon': 'bx bx-desktop'}
   },
   {
      path: '/cuponsGerados/:idTab',
      name: 'Cupons_gerados',
      component: () => import('../views/pages/pdv/CuponsGerados.vue'),
      meta: {'icon': 'bx bx-desktop'}
   },
   {
      path: '/caixa/:idTab',
      props: route => ({ query: route.query }),
      name: 'Caixa',
      component: () => import('../views/pages/pdv/Caixa.vue'),
      meta: {'icon': 'bx bx-desktop'}
   },
   {
      path: '/noticia/:idTab',
      props: route => ({ query: route.query }),
      name: 'Notícia',
      component: () => import('../views/pages/Noticia.vue'),
      meta: {'icon': 'bx bx-conversation'}
   },
   {
      path: '/noticias/:idTab',
      name: 'Notícias',
      component: () => import('../views/pages/Noticias.vue'),
      meta: {'icon': 'bx bx-conversation'}
   }
]

const router = new VueRouter({
   mode: 'abstract',
   base: process.env.BASE_URL,
   routes
})

/* eslint-disable-next-line no-unused-vars */
router.beforeResolve((to, from, next) => {
   let inititalData = null;

   if (to.matched.length > 0 && to.matched[0].components.default.data) {
      inititalData = to.matched[0].components.default.data()
   }

   store.dispatch('addTabData', {'id': to.params.idTab, 'nome': to.name, 'path': to.path, 'query': to.query, 'icon': to.meta.icon, 'data': inititalData})

   $(".dropdown-toggle.show").dropdown("hide");
   $("#app > .wrapper").removeClass("toggled");
   $(".mm-active a").click()
   
   setTimeout(() => {
      $("#menu .mm-active").removeClass('mm-active')
      $("#menu .mm-show").removeClass('mm-show')
   }, 150);
   
   next()
})

export default router
